import * as React from "react"
import * as style from '../styles/contentBox.module.css'
import GetInTouch from "./GetInTouch"


export default function Intro() {
  return (
    <>
      <div className={style.contentBox}>
        <p><span>I'm Phil</span>, co-founder of <a href="https://www.dreamstories.ai/" rel="noreferrer" target="_blank">DreamStories.ai</a> — a hyper-personalized media platform that turns storytelling into an interactive experience.</p>
        <p>Previously, I was a Data Scientist at <a href='https://www.uber.com/' rel='noreferrer' target='_blank'>Uber</a> and first Data Scientist at <a href='https://www.kencko.com/' rel='noreferrer' target='_blank'>kencko</a>.</p>
        <p>I hold degrees in Computer Science & Engineering from the Polytechnic University of Milan and in Data Science from Polytechnic University of Madrid in Spain.</p>
        {GetInTouch()}
      </div>
      {/* <div className={style.icons}>
        <a href="https://www.linkedin.com/in/philcalzavara/" rel='noreferrer' target="_blank">
          <img src={'linkedin.webp'} width="35px" alt="LinkedIn" />
        </a>
        <a href="https://github.com/philosss" rel='noreferrer' target="_blank">
          <img src={'github.webp'} width="35px" alt="GitHub" />
        </a>
      </div> */}


    </>
  )
}
