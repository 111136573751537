import React from 'react'
import * as style from '../styles/contentBox.module.css'
import GetInTouch from './GetInTouch'

export default function History() {
  return (
    <>
      <div className={style.contentBox}>
        <h6>September 2023</h6>
        <p><a href='https://www.dreamstories.ai/' rel='noreferrer' target='_blank'><span>At DreamStories.ai</span></a> we're building an hyper-personalized media platform that puts you and your loved ones at the center of your own story.</p>
        <p>By blending AI, product design, and data, DreamStories empowers anyone to create unique, multi-format content tailored to their world—reflecting their realities, ambitions, and culture.</p>
        <p>DreamStories represents a novel intersection between art and data science. If you're a talented engineer and love the challenge, <a href='https://dreamstories.ai/pages/jobs' rel='noreferrer' target='_blank'>Join the DreamTeam 🚀</a></p>
        <div className='center'><a href='https://www.dreamstories.ai/' rel='noreferrer' target='_blank'><img src={'/dreamstories.webp'} width="500px" alt='A book created with dreamstories.ai' /></a></div>
      </div >
      <div className={style.contentBox}>
        <h6>March 2022</h6>
        <p><a href='https://www.uber.com/' rel='noreferrer' target='_blank'><span>At Uber</span></a>,
          I designed data-driven payment systems that ensured seamless transactions for millions of daily trips.</p>
        <p>I conducted experiments on products affecting over 20 million daily trips, contributing to more than $10 million in combined annual gross bookings.</p>

        <div className='center'><img src={'/Uber.webp'} alt="A screenshot of Uber's app" /></div>
      </div>
      <div className={style.contentBox}>
        <h6>Sept 2019</h6>
        <p><a href='https://www.kencko.com/' rel='noreferrer' target='_blank'><span>At kencko</span></a>,
          as the first Data Scientist and early employee, I established the data architecture, created ETL pipelines and implemented BI tools.</p>
        <p>During my tenure, I worked closely with the CEO and CBO on data for the Series A funding, which was featured on <a href='https://techcrunch.com/2022/01/25/kencko-takes-in-new-capital-to-shake-up-how-we-consume-our-fruits-vegetables/' rel='noreferrer' target='_blank'>TechCrunch</a>.</p>

        <div className='center'><img src={'/kencko.webp'} alt='A bottle of kencko, instant smoothie' /></div>
      </div>
      <div className={style.contentBox}>
        <h6>Feb 2019</h6>
        <p><a href='https://www.geoblink.com/' rel='noreferrer' target='_blank'><span>At Geoblink</span></a>,
          I utilized geolocated datasets to build Machine Learning models predicting the unemployment rate and disposable income of households.</p>
        <p>Additionally, I built ETL pipelines on Airflow to fetch, transform, and store data, contributing to model development.</p>

        <div className='center'><img src={'/geoblink.webp'} alt='A screenshot of Geoblink, a location intelligence tool' /></div>
        {GetInTouch()}
      </div>
    </>
  )
}
